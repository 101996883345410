import ProgressBar from 'progressbar.js';

let containers = document.querySelectorAll('.progress-bar');
containers.forEach(container => {
  let progressElement = container.querySelector('.progress');
  let progressALL = container.querySelector('.progress__all');
  let value = parseInt(progressElement.textContent) / parseInt(progressALL.textContent);
  let bar = new ProgressBar.Circle(container, {
    color: '#ffffff',
    strokeWidth: 8,
    trailWidth: 8,
    easing: 'easeInOut',
    duration: 1400,
    from: { color: '#515151', width: 8 },
    to: { color: '#ffffff', width: 8 },

    step: function (state, circle) {
      circle.path.setAttribute('stroke', state.color);
      circle.path.setAttribute('stroke-width', state.width);
    }
  });

  bar.animate(value);
});


